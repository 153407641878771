














































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import WheelScanning from '@/views/integrations/tread-depth-reader/WheelScanning.vue'
import Continova from '@/views/integrations/tread-depth-reader/Continova.vue'
import Beissbarth from '@/views/integrations/tread-depth-reader/Beissbarth.vue'
import Inventech from '@/views/integrations/tread-depth-reader/Inventech.vue'
import HamaNord from '@/views/integrations/tread-depth-reader/HamNord.vue'
import VeCheck from '@/views/integrations/tread-depth-reader/VeCheck.vue'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { WheelScanning, Continova, Beissbarth, Inventech, HamaNord, VeCheck },
})
export default class Form extends Vue {
  public $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
    clone: {
      focus: any
    }
  }

  private editView = false
  private defaultTreadDepthReader = {
    name: null,
    token: null,
    reportType: 1,
    updateOption: 'READING_SEASON',
    updateTyreHotelTreadDepths: false,
    updateTyreHotelTyreData: false,
    updateLastTyreHotelCertificate: false,
    deviceType: null,
    wheelScanning: {},
    continova: {},
    inventech: {},
    beissbarth: {},
    hamnord: {},
    vecheck: {},
  }

  private treadDepthReader = null

  private newTokenLoading = false
  private newTokenDialog = false

  private deviceTypes = []

  private treadDepthReaders = []

  private promptDelete = false
  private deleteId = null

  private valid = true
  private loadingData = true
  private createIsLoading = false
  private saveIsLoading = false

  private rules = {
    deviceType: null,
    reportType: null,
    name: null,
    token: null,
  }

  private updateOptions = [
    {
      value: 'READING_SEASON',
      text: this.$t('c:tread-depth-reader:Based on the reading season'),
    },
    {
      value: 'WHEEL_SET_ON_CAR',
      text: this.$t('c:tread-depth-reader:Wheel set that is on car'),
    },
    {
      value: 'WHEEL_SET_IN_HOTEL',
      text: this.$t('c:tread-depth-reader:Wheel set that is in hotel'),
    },
  ]

  private created() {
    this.resetTreadDepthReaderObject()
    this.loadTreadDepthReaders()
    this.loadDeviceTypes()
    this.onRouteChange()
  }

  private resetTreadDepthReaderObject() {
    this.treadDepthReader = JSON.parse(JSON.stringify(this.defaultTreadDepthReader))
  }

  @Watch('$route', { deep: true })
  private onRouteChange(): void {
    this.loadingData = true
    this.editView = !!this.$route.params.id

    if (this.editView) {
      this.rules = {
        deviceType: null,
        reportType: [(v) => !!v || this.$t('c:validation:This field is required')],
        name: [(v) => !!v || this.$t('c:validation:This field is required')],
        token: [(v) => !!v || this.$t('c:validation:This field is required')],
      }

      this.getEditData()
    } else if (!this.$route.meta.all) {
      this.rules = {
        deviceType: [(v) => !!v || this.$t('c:validation:This field is required')],
        reportType: [(v) => !!v || this.$t('c:validation:This field is required')],
        name: [(v) => !!v || this.$t('c:validation:This field is required')],
        token: [(v) => !!v || this.$t('c:validation:This field is required')],
      }

      this.resetTreadDepthReaderObject()
      this.getNewToken()
    }
  }

  private loadDeviceTypes() {
    this.$axios
      .get('/v4/site/integration/tread-depth-reader/device-types')
      .then((response) => {
        const data = response.data
        this.deviceTypes = data.deviceTypes
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to get the device types'))
      })
  }

  private getEditData() {
    this.$axios
      .get('/v4/site/integration/tread-depth-reader/' + this.$route.params.id)
      .then((response) => {
        const data = response.data
        this.treadDepthReader = data.treadDepthReader
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to get the tread depth reader data'))
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  private loadTreadDepthReaders() {
    this.$axios
      .get('/v4/site/integration/tread-depth-reader')
      .then((response) => {
        const data = response.data
        this.treadDepthReaders = data.treadDepthReaders
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to get the tread depth readers'))
      })
  }

  private openDeletePrompt(id) {
    this.promptDelete = true
    this.deleteId = id
  }

  private closeDeletePrompt() {
    this.promptDelete = false
    this.deleteId = null
  }

  private deleteTreadDepthReader() {
    this.$axios
      .delete('/v4/site/integration/tread-depth-reader/' + this.deleteId)
      .then(() => {
        this.loadTreadDepthReaders()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to delete a tread depth reader'))
      })
      .finally(() => {
        this.closeDeletePrompt()
      })
  }

  private newTokenPrompt() {
    if (this.editView) {
      this.newTokenDialog = true
    } else {
      this.getNewToken()
    }
  }

  private confirmNewToken() {
    this.getNewToken()
    this.newTokenDialog = false
  }

  private getNewToken() {
    this.newTokenLoading = true
    this.$axios
      .get('/v4/site/integration/tread-depth-reader/new-token')
      .then((response) => {
        this.treadDepthReader.token = response.data.token
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to get a new token'))
      })
      .finally(() => {
        this.newTokenLoading = false
        this.loadingData = false
      })
  }

  private copyTokenToClipboard() {
    this.$refs.clone.focus()
    document.execCommand('copy')
  }

  private create() {
    if (this.$refs.form.validate()) {
      this.createIsLoading = true
      this.$axios
        .post('/v4/site/integration/tread-depth-reader', this.treadDepthReader)
        .then(() => {
          this.loadTreadDepthReaders()
          this.resetTreadDepthReaderObject()
          this.$router.push({ name: 'Integrations/TreadDepthReader' })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to create the tread depth reader'))
        })
        .finally(() => {
          this.createIsLoading = false
        })
    }
  }

  private save() {
    if (this.$refs.form.validate()) {
      this.saveIsLoading = true
      this.$axios
        .put('/v4/site/integration/tread-depth-reader/' + this.$route.params.id, this.treadDepthReader)
        .then(() => {
          this.loadTreadDepthReaders()
          this.resetTreadDepthReaderObject()
          this.$router.push({ name: 'Integrations/TreadDepthReader' })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, this.$t('c:tread-depth-reader:Failed to save the tread depth reader'))
        })
        .finally(() => {
          this.saveIsLoading = false
        })
    }
  }

  private get reportTypes() {
    const result = [
      {
        id: 'NONE',
        name: this.$t('c:tread-depth-reader:Do not send a report'),
      },
      {
        id: 'EONTYRE',
        name: this.$t('c:tread-depth-reader:Eontyre report'),
      },
    ]

    if (this.treadDepthReader.deviceType) {
      for (let i = 0; i < this.deviceTypes.length; i++) {
        if (this.deviceTypes[i].name === this.treadDepthReader.deviceType && this.deviceTypes[i].hasPdf) {
          result.push({
            id: 'DEVICE',
            name: this.$t('c:tread-depth-reader:Device report'),
          })
        }
      }
    }

    return result
  }
}
